import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Signup from "../components/Login/Signup";
import Login from "../components/Login/Login";
import ResetPassword from "../components/Login/ResetPassword";
import Dashboard from "../components/Dashboard";
import DriverLogsTable from "../components/Reports/DriverLogsTable";
import LiveTracking from "../components/LiveTracking/LiveTracking";
import DriverLog from "../components/DriverLog/DriverLog";
import InspectionLogs from "../components/InspectionLogs/InspectionLogs";
import Companies from "../components/Administration/Companies/Companies";
import Drivers from "../components/Administration/Drivers/Drivers";
import Vehicles from "../components/Administration/Vehicles/Vehicles";
import WebUsers from "../components/Administration/WebUsers/WebUsers";
import WebUserPage from "../components/Administration/WebUsers/WebUserPage";
import WebUserCreate from "../components/Administration/WebUsers/WebUserCreate";
import ProtectedRoute from './ProtectedRoute';
import ELDLog from "../components/Support/ELDLogs";
import Welcome from "./Welcome";
import Devices from "../components/Administration/Devices/Devices";
import AdminPage from "../admin/AdminPage";
import MobileChart from "../components/Mobile/MobileChart";

const MainRouter = () => {

  return (
    <Router>
      <Routes>
        <Route path="chart" element={<MobileChart />} />
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
          {/* Nested routes for DriverLogsTable and LiveTracking etc.*/}
          <Route path="admin-page" element={<AdminPage />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="driver-logs" element={<DriverLogsTable />} />
          <Route path="driver-log" element={<DriverLog />} />
          <Route path="live-tracking" element={<LiveTracking />} />
          <Route path="inspection-logs/" element={<InspectionLogs />} />
          <Route path="companies" element={<Companies />} />
          <Route path="drivers" element={<Drivers />} />
          <Route path="vehicles" element={<Vehicles />} />
          <Route path="devices" element={<Devices />} />
          <Route path="web-users" element={<WebUsers />} />
          <Route path="web-users/web-user/:id" element={<WebUserPage />} />
          <Route path="web-users/web-user/add-web-user" element={<WebUserCreate />} />
          <Route path="support/ELDLog" element={<ELDLog />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
};

export default MainRouter;
