import { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Button, Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { ELDLogContext } from '../context/ELDLogContext';
import { useLocation } from "react-router-dom";

const ELDLogDatePicker = () => {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [selectData, setSelectData] = useState<{ value: string; label: string; }[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
  const [selectError, setSelectError] = useState(false);
  const [datePickerError, setDatePickerError] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const maxDaysInRange = 8;
  const { driversList } = useContext(ELDLogContext);

  useEffect(() => {
    const fetchDriversList = () => {
      try {
        if (driversList) {
          setSelectData(driversList.map((driver) => ({ value: driver.id, label: `${driver.lastName} ${driver.firstName}` })));
        }
      } catch (error) {
        console.error('Error fetching drivers list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDriversList();
  }, [driversList]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');
    const to = queryParams.get('to');

    if (from && to) {
      const fromDate = new Date(from.split('T')[0]);
      const toDate = new Date(to.split('T')[0]);
      setValue([fromDate, toDate]);
    }
  }, [location.search]);

  const handleRangeChange = (newRange: [Date | null, Date | null]) => {
    if (newRange[0]) {
      const start = DateTime.fromJSDate(newRange[0]);
      const end = newRange[1] ? DateTime.fromJSDate(newRange[1]) : start;
      const difference = end.diff(start, "days").days;

      if (difference <= maxDaysInRange - 1) {
        setValue([newRange[0], newRange[1]]);
      } else {
        setValue([newRange[0], start.plus({ days: maxDaysInRange - 1 }).toJSDate()]);
      }
    } else {
      setValue([null, null]);
    }
    setSelectError(false);
    setDatePickerError(false);
  };

  const handleShowRange = (): void => {
    const startDate = value[0] ? DateTime.fromJSDate(value[0]).toISODate() : null;
    const endDate = value[1] ? DateTime.fromJSDate(value[1]).toISODate() : null;

    if (!selectedDriver) {
      setSelectError(true); // Set select input error to true if it's empty
    } else {
      setSelectError(false); // Reset select input error
    }

    if (!startDate || !endDate) {
      setDatePickerError(true); // Set date picker input error to true if either is empty
    } else {
      setDatePickerError(false); // Reset date picker input error
    }

    if (startDate && endDate && selectedDriver) {
      window.location.href = `/dashboard/support/ELDLog?id=${selectedDriver}&to=${endDate}T23:59:59.999Z&from=${startDate}T00:00:00.000Z`;
    }
  };

  return (
    <div className="driverLogDatePicker">
      <Select
        className="driverLogDatePickerItem"
        label="Select driver"
        data={selectData}
        disabled={loading}
        searchable
        clearable
        value={selectedDriver}
        onChange={(newValue) => {
          setSelectedDriver(newValue);
          setSelectError(false);
        }}
        placeholder={selectError ? "Please select a driver" : "Enter driver's name"}
        error={selectError}
      />
      <DatePickerInput
        miw={300}
        maw={330}
        clearable
        allowSingleDateInRange
        maxDate={new Date()}
        className="driverLogDatePickerItem"
        type="range"
        value={value}
        onChange={handleRangeChange}
        label={`Select range (max ${maxDaysInRange} days)`}
        placeholder={datePickerError ? "Please select a date range" : "Enter date range"}
        error={datePickerError}
      />
      <Button className='driverLogDatePickerItem' onClick={handleShowRange} color="green">{`Show range`}</Button>
    </div>
  );
}

export default ELDLogDatePicker;
