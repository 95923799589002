type PageNames = Record<string, string>;

export const pageNames: PageNames = {
    "/dashboard/welcome": "Welcome",
    "/dashboard/driver-logs": "Driver Logs Table",
    "/dashboard/driver-log": "Driver Log",
    "/dashboard/live-tracking": "Live Tracking",
    "/dashboard/inspection-logs": "Inspection Logs",
    "/dashboard/companies": "Companies",
    "/dashboard/drivers": "Drivers",
    "/dashboard/drivers/driver/:id": "Drivers Page",
    "/dashboard/drivers/driver/add-driver": "Create Driver",
    "/dashboard/vehicles": "Vehicles",
    "/dashboard/vehicles/vehicle/:id": "Vehicle Page",
    "/dashboard/vehicles/vehicle/add-vehicle": "Create Vehicle",
    "/dashboard/web-users": "Web Users",
    "/dashboard/web-users/web-user/:id": "Web User Page",
    "/dashboard/web-users/web-user/add-web-user": "Create Web User",
    "/dashboard/support/ELDLog": "ELD Log",
};