import { useContext,useState,useEffect } from 'react';
import { useSearchParams,Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import DriverLogLegend from './ELDLogLegend';
import DriverLogPrevNextBtns from './ELDLogPrevNextBtns';
import { parseELDDataToApexFormat } from '../../../../utils/DriverLogChart';
import { ELDLogContext } from '../context/ELDLogContext';
import { Button, Flex, LoadingOverlay } from '@mantine/core';
import { useDriverLog } from '../context/ELDLogContext';


function formatMilliseconds(milliseconds) {
  let totalSeconds = Math.floor(milliseconds / 1000);
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = totalSeconds % 60;

  return `${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
}

function cutDriverLog(str,range,normalize) {
  const arr = str.split('\n');
  let [startRange,endRange] = range.split('-').map(Number);

  // If endRange is not provided, use startRange as endRange- (for single date case)
  endRange = endRange || startRange;

  const containDate = (str,searchString) => {
    return arr.some(line => line.includes(searchString));
  }

  const foundInRange = containDate(str,startRange);

  if (!foundInRange) {
    let sliceStart = 0;
    let sliceEnd = 0;

    for (let i = 0; i <= arr.length - 2; i++) {
      const date = arr[i].split(',')[5]; // assuming the date is always at the 6th position in the driverLog
      const prevDate = arr[i + 1].split(',')[5];

      if (date !== prevDate) {
        sliceStart = Math.max(i,0);
        sliceEnd = i + 2;
        break;
      }
    }

    const resultString = arr.slice(sliceStart,sliceEnd).join('\n');

    if (normalize) {
      return normalizeReport(resultString,startRange);
    }

    return resultString;
  }

  let firstIndex = -1;
  let lastIndex = -1;

  arr.forEach((line,index) => {
    const date = line.split(',')[5];
    const numericDate = Number(date);
    if (numericDate >= startRange && numericDate <= endRange) {
      if (firstIndex === -1) firstIndex = index;
      lastIndex = index;
    }
  });

  // Adjusting the slice to handle edge cases
  firstIndex = Math.max(firstIndex - 1,0);
  lastIndex = Math.min(lastIndex + 1,arr.length - 1);

  const resultString = arr.slice(firstIndex,lastIndex + 1).join('\n');
  if (normalize) {
    return normalizeReport(resultString,startRange)
  }

  return resultString;
}

function normalizeReport(driverLog) {
  const currentUrl = new URL(window.location.href);
  const urlParams = currentUrl.searchParams;
  const dateString = urlParams.get('from');
  const isToday = dateString === getDateInFormat(new Date());

  const date = new Date(`20${dateString.substring(4,6)}`,dateString.substring(0,2) - 1,dateString.substring(2,4));
  date.setDate(date.getDate() + 1);
  const nextDate = `${String(date.getMonth() + 1).padStart(2,'0')}${String(date.getDate()).padStart(2,'0')}${date.getFullYear().toString().substring(2)}`;

  const arr = driverLog.trim().split('\n').map(line => line.trim());
  const processedLogs = arr.map(logEntry => logEntry.split(','));

  if (processedLogs.length > 0) {
    processedLogs[0][5] = dateString;
    processedLogs[0][6] = '000000';

    if (isToday && processedLogs.length > 1) {
      const currentTime = getTimeInFormat(new Date());
      processedLogs[processedLogs.length - 1][5] = dateString;
      processedLogs[processedLogs.length - 1][6] = currentTime;
    } else if (processedLogs.length > 1) {
      processedLogs[processedLogs.length - 1][5] = nextDate;
      processedLogs[processedLogs.length - 1][6] = '000000';
    }
  }

  let modifiedDriverLog = processedLogs.map(fields => fields.join(',')).join('\n');
  return modifiedDriverLog;
}

function getDateInFormat(date) {
  const month = String(date.getMonth() + 1).padStart(2,'0');
  const day = String(date.getDate()).padStart(2,'0');
  const year = String(date.getFullYear()).substring(2);
  return `${month}${day}${year}`;
}

function getTimeInFormat(date) {
  const hours = String(date.getHours()).padStart(2,'0');
  const minutes = String(date.getMinutes()).padStart(2,'0');
  const seconds = String(date.getSeconds()).padStart(2,'0');
  return `${hours}${minutes}${seconds}`;
}

function getUTCTimeInFormat(date) {
  const hours = String(date.getUTCHours()).padStart(2,'0');
  const minutes = String(date.getUTCMinutes()).padStart(2,'0');
  const seconds = String(date.getUTCSeconds()).padStart(2,'0');
  return `${hours}:${minutes}:${seconds}`;
}

function createDatesForYear(dateString) {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const inputDate = new Date(year,month,day);

  const startDate = new Date(inputDate.getFullYear(),inputDate.getMonth() - 1,1);
  const endDate = new Date(inputDate.getFullYear(),inputDate.getMonth() + 2,0);

  const dates = [];

  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateWithNoTimezone = new Date(Date.UTC(d.getFullYear(),d.getMonth(),d.getDate()));
    dates.push({
      x: dateWithNoTimezone.getTime(),
      borderColor: '#775DD0'
    });
  }

  return dates
}

function DriverLogTimeChart() {
  const { driverLog, refetchDriverLog, currentDriver, loading } = useDriverLog();

  const [searchParams] = useSearchParams();
  const dateRange = searchParams.get('from');
  // const { driverLog } = useContext(ELDLogContext);
  const [chartData,setChartData] = useState({
    series: [{ data: [] }],
    durations: [],
    options: {
      chart: {
        id: 'driverlog',
        type: 'rangeBar',
      },
      dataLabels: {
        // dataLabels removed temporary
        // enabled: true,
        // formatter: function (value) {
        //   const date1 = new Date(value[0]);
        //   const date2 = new Date(value[1]);
        //   return formatMilliseconds(date2 - date1);
        // },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels:
        {
          show: true,
          style: {
            fontWeight: 'bold',
            fontSize: '18px',
            alignText: 'right'
          }
        },
      },
      tooltip: {
        custom: function ({ series,seriesIndex,dataPointIndex,w }) {
          const element = w.config.series[0].data[dataPointIndex]

          return `
          <div class="arrow_box">
            <div class="arrow_box_time">
              <div class="arrow_box_time_item">
                <p class="arrow_box_time_item_date">
                ${getDateInFormat(new Date(element.y[seriesIndex])).substring(0,2)}/${getDateInFormat(new Date(element.y[seriesIndex])).substring(2,4)}
                </p>
                <p class="arrow_box_time_item_time">
                ${getUTCTimeInFormat(new Date(element.y[seriesIndex]))}
                </p>
              </div>
              <div class="arrow_box_time_divider">
              </div>
              <div class="arrow_box_time_item">
                <p class="arrow_box_time_item_date">
                ${getDateInFormat(new Date(element.y[seriesIndex + 1])).substring(0,2)}/${getDateInFormat(new Date(element.y[seriesIndex + 1])).substring(2,4)}
                </p>
                <p class="arrow_box_time_item_time">
                ${getUTCTimeInFormat(new Date(element.y[seriesIndex + 1]))}
                </p>
                <p class="arrow_box_time_item_time">
                ${element.rangeName}
                </p>
              </div>
            </div>
            <div class="arrow_box_footer">
              <div class="arrow_box_round arrow_box_round_${(element.x).toLowerCase()}"></div>
              <div class="arrow_box_duration">
                  <p>${element.x}: ${formatMilliseconds((element.y[seriesIndex + 1]) - (element.y[seriesIndex]))}</p>
              </div>
            </div>
          </div>
            `
        }
      },
      noData: {
        text: 'no logs for this date or data is empty',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#775DD0',
          fontSize: '20px',
          fontFamily: undefined
        }
      },

      annotations: {
        xaxis: [...createDatesForYear(dateRange)]
      },
    },
  })
  console.log('chartData',chartData);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [loading]);

  useEffect(() => {
    const isNormalized = window.location.search.includes('&normalize=true');

    if (driverLog) {
      // driverLog.driverLog = cutDriverLog(driverLog.driverLog, dateRange, isNormalized)
      const todaysData = parseELDDataToApexFormat(driverLog);
      setChartData(prevData => ({
        ...prevData,
        series: [{ data: todaysData.apexData ? todaysData.apexData : [] }],
        durations: todaysData.durationByStatus,
        options: {
          ...prevData.options,
          annotations: {
            xaxis: [...createDatesForYear(dateRange),...todaysData.pointAnnotations]
          }
        }
      }));
    }
  },[driverLog]);

  const renderTimeValues = () => {
    return chartData.durations.map(({ status,duration }) => (
      <div key={status}>{formatMilliseconds(duration)}</div>
    ));
  };

  const normalizedHref = () => {
    const currentUrl = new URL(window.location.href);
    const urlParams = currentUrl.searchParams;
    const dateParam = urlParams.get('date');

    if (dateParam) {
      const firstDate = dateParam.split('-')[0];
      urlParams.set('date',firstDate);
    }

    return currentUrl.href.includes('normalize=true')
      ? currentUrl.href
      : currentUrl.href + '&normalize=true';
  }

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <div id="driverLogTimeChart">
        <div className='driverLogTimeChartBtns'>
          <Button style={{ backgroundColor: '#050505',margin: '0px 15px' }}>
            <a href={`${normalizedHref()}`} style={{ textDecoration: 'none',color: '#fff' }}>
              Normalize report
            </a>
          </Button>
          <DriverLogPrevNextBtns />
        </div>
        <div>
          <div className='driverLogTimeChartContainer'>
            <div className='driverLogTimeChartItem'>
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="rangeBar"
                height={380}
              />
            </div>
            <div className='driverLogTimeChartItem'>
              <div className='driverLogTimeChartDaily'>
                {renderTimeValues()}
              </div>
            </div>
          </div>
        </div>
        <div className='driverLogLegend'><DriverLogLegend /></div>
      </div>
    </>
  );
}

export default DriverLogTimeChart;
