import { ActionIcon, Flex } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';

const ScrollButtons = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const scrollToBottom = () => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    };

    return (
        <Flex gap="md" direction="column" sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 100 }}>
            <ActionIcon
                variant="filled"
                radius="xl"
                onClick={scrollToTop}
                color="blue"
                size="lg"
            >
                <IconArrowUp style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>
            <ActionIcon
                variant="filled"
                radius="xl"
                onClick={scrollToBottom}
                color="blue"
                size="lg"
            >
                <IconArrowDown style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>
        </Flex>
    );
};

export default ScrollButtons;
