import { TextInput, Text, Menu, Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { CreateDeviceModalProps, DeleteDeviceModalProps, EditDeviceModalProps } from "./Devices.types";
import { notifications } from "@mantine/notifications";
import { handleDeleteDeviceSubmit } from "./Devices.utils";
import DeviceCreateForm from "./DeviceCreateForm";
import { initialValues } from "./Device.constants";
import DeviceEditForm from "./DeviceEditForm";

export const DeleteDeviceModal: React.FC<DeleteDeviceModalProps> = ({ selectedDevice }) => {
    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: `Delete Device`,
            centered: true,
            children: (
                <>
                    <Text size="sm" mb={20}>
                        Are you sure you want to delete this device?
                        If you are sure, please type <Text span c="blue" inherit>'delete'</Text> into the box.
                    </Text>
                    <TextInput
                        placeholder="Enter confirmation word"
                        title="Enter 'delete' to confirm"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => localStorage.setItem('DeviceDeleteConfirmationWord', event.currentTarget.value)}
                    />
                </>
            ),
            labels: { confirm: 'Delete device', cancel: "No don't delete" },
            confirmProps: { color: 'red' },
            onCancel: () => {
                notifications.show({
                    title: 'Operation canceled',
                    message: `Deletion of canceled.`,
                });
            },
            onConfirm: () => {
                const confirmationWord = localStorage.getItem('DeviceDeleteConfirmationWord');
                if (confirmationWord === 'delete') {
                    handleDeleteDeviceSubmit(selectedDevice);
                    localStorage.removeItem('VehicleDeleteConfirmationWord');
                } else {
                    console.log(confirmationWord)
                    notifications.show({
                        title: 'Incorrect confirmation word',
                        message: `Please type 'delete' to confirm deletion.`,
                        color: 'red',
                    });
                }
            },
        });

    return (
        <Menu.Item style={{ textAlign: 'center' }} onClick={openDeleteModal} color='red'>
            Delete device
        </Menu.Item>
    );
};

export const EditDeviceModal: React.FC<EditDeviceModalProps> = ({ selectedDevice }) => {
    const openUpdateModal = () =>
        modals.open({
            title: `Edit device`,
            centered: true,
            size: 'auto',
            children: (<DeviceEditForm selectedDevice={selectedDevice}/>),
        });

    return <Menu.Item style={{ textAlign: 'center' }} onClick={openUpdateModal}>
    Edit
</Menu.Item>
};

export const CreateDeviceModal: React.FC<CreateDeviceModalProps> = () => {
    const openCreateModal = () =>
        modals.open({
            title: `Add logging device`,
            centered: true,
            size: 'auto',
            children: (
            <DeviceCreateForm initialValues={initialValues} />
        ),
        });

    return <Button
        onClick={openCreateModal}
        color="green"
        size='sm'
        mr={"lg"}>
        + Add device
    </Button>;
}