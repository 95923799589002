import { DriverLogProvider } from './context/ELDLogContext';
import DriverLogHeader from './components/ELDLogHeader';
import DriverLogDonuts from './components/ELDLogDonuts';
import DriverLogTimeChart from './components/ELDLogTimeChart';
import ELDLogChartTable from './components/tableComponents/ELDLogChartTable';
import ScrollPage from '../../Shared/Components/ScrollPage';

const ELDLog = () => {
  return (
    <DriverLogProvider>
      <ScrollPage />
      <DriverLogHeader />
      <div className='chart'>
        <DriverLogDonuts />
        <div className='apexContainer'>
          <DriverLogTimeChart />
        </div>
      </div>
      <div className='driverLogTable'>
        <ELDLogChartTable />
      </div>
    </DriverLogProvider>
  );
};

export default ELDLog;
