import { HandleCellEditProps } from "../types/cellEdit.types";

export const handleCellEdit = ({
  cell,
  eventValue,
  originalData,
  setData,
  setChangedCells,
}: HandleCellEditProps) => {
  const value = eventValue;
  const [idIndex, idName] = cell.id.split('_');
  const initialValue = originalData?.[idIndex as any]?.[idName];

  if (String(initialValue ?? '') !== String(value)) {
    setData((prevData) =>
      prevData.map((row, index) =>
        index === +idIndex ? { ...row, [cell.column.id]: value } : row
      )
    );

    setChangedCells((prevChangedCells) => {
      const updatedSet = new Set([...prevChangedCells, cell.id]);
      return Array.from(updatedSet);
    });
  } else {
    setChangedCells((prevChangedCells) => {
      return prevChangedCells.filter((id) => id !== cell.id);
    });
  }
};
