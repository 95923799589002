import { Button, Flex, Group, Paper, Text, TextInput } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { modals } from "@mantine/modals";

const ShiftEventeModal = ({ table, selectedRows, onShiftEvent }: { table: any; selectedRows: any; onShiftEvent: any }) => {

    console.log('selectedRows', selectedRows);

    return (
        <Paper miw={400} mih={450} p={30}>

            <DateTimePicker
                label="Pick date and time"
                placeholder="Pick date and time"
                maw={400}
                mx="auto"
                withSeconds
                dropdownType="popover"
            />
            {/* <Flex maw={400} mx="auto" direction={'row'}>
<TextInput type='date'></TextInput>
<TextInput type='time'></TextInput>
</Flex> */}
            <Group m={20} align="center" w={'100%'} grow>
                <Button>Confirm</Button>
                <Button color="red" onClick={()=>modals.closeAll()}>Cancel</Button>
            </Group>
        </Paper >
    );
};

export default ShiftEventeModal;
