import { UserLog } from '../types/ELDLog.types';

export const handleRemoveEvent = (
    selectedRows: UserLog[],
    data: UserLog[],
    setData: (data: UserLog[]) => void,
    setDeletedRows: React.Dispatch<React.SetStateAction<UserLog[]>>, 
    setChangedCells: React.Dispatch<React.SetStateAction<string[]>>, 
    table: any
  ) => {
    const rowsToDelete: UserLog[] = selectedRows.map(row => ({
      ...row,
      event_status: 'deleted',
    }));
  
    const updatedData = data.map(item =>
      rowsToDelete.some(row => row.id === item.id) ? { ...item, event_status: 'deleted' } : item
    );
  
    const filteredData = updatedData.filter(item => item.event_status !== 'deleted');
    setData(filteredData);
  
    setDeletedRows((prev) => [...prev, ...rowsToDelete]);
  
    setChangedCells((prev) => [...prev, ...selectedRows.map(row => row.id.toString())]);
  
    table.resetRowSelection();
  };
  