import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { fetchDriverLog } from './ELDLogService';

// http://localhost:3001/chart?driverid=aded6721-19b1-40fd-861c-159f655a6aba&from=2024-11-14T00:00:00.000Z&to=2024-11-14T23:59:59.999Z&timeZone=America/Chicago&companyName=32&accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiOWNmY2IzNjItNWE3Mi00MDRmLTk4OTctMDM5ZTk2ZTA2OGZmIiwiZW1haWwiOiJ0ZXN0LXVzZXJAZ21haWwuY29tIiwiZmlyc3ROYW1lIjoiZmlyc3ROYW1lIGxhc3ROYW1lIiwibGFzdE5hbWUiOiJsYXN0TmFtZSJ9LCJyb2xlIjp7ImlkIjoyLCJyb2xlIjoiQWRtaW4ifSwiaWF0IjoxNzMxNjg4MTE3LCJleHAiOjE3MzE2OTE3MTd9.t-EuQ_zYeMXJhGGyb5Xonaym3NYJEzy1laHFNsELPDU&refreshToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiOWNmY2IzNjItNWE3Mi00MDRmLTk4OTctMDM5ZTk2ZTA2OGZmIiwiZW1haWwiOiJ0ZXN0LXVzZXJAZ21haWwuY29tIiwiZmlyc3ROYW1lIjoiZmlyc3ROYW1lIGxhc3ROYW1lIiwibGFzdE5hbWUiOiJsYXN0TmFtZSJ9LCJyb2xlIjp7ImlkIjoyLCJyb2xlIjoiQWRtaW4ifSwiaWF0IjoxNzMxNjg0OTI1LCJleHAiOjE3MzE3NzEzMjV9.Ri5IgOoGDrjWiz5tsAdLm5dlWcQrjyece5q6z2m24M8


const getQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    driverId: searchParams.get('driverid') || '',
    timeFrom: searchParams.get('from') || '',
    timeTo: searchParams.get('to') || '',
    timeZone: searchParams.get('timeZone') || '',
    accessToken: searchParams.get('accessToken') || '',
    refreshToken: searchParams.get('refreshToken') || '',
    companyName: searchParams.get('companyName') || '',
  };
};

const statusMapping: { [key: string]: number } = {
  "off": 3.5,
  "sleep": 2.5,
  "on_duty": 0.5,
  "driving": 1.5,
};

const MobileChart = () => {
  const [chartData, setChartData] = useState({
    series: [] as { name: string; data: { x: number; y: number }[] }[],
    options: {
      chart: {
        type: 'line' as 'line',
        height: 200,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'straight' as 'straight',
        width: 2,
      },
      colors: ['#FF0000'],
      xaxis: {
        type: 'numeric' as 'numeric',
        min: 0,
        max: 86400000,
        tickAmount: 24,
        labels: {
          formatter: (value: string) => {
            const val = Number(value);
            const date = new Date(val);
            return date.toISOString().slice(11, 13);
          },
          style: {
            fontSize: '10px',
          },
        },
      },
      yaxis: {
        min: 0,
        max: 4,
        tickAmount: 4,
        labels: {
          show: true,
          formatter: (val: number): string => {
            const customLabels: { [key: number]: string } = {
              3: 'OFF',
              2: 'SB',
              1: 'DRV',
              0: 'ON',
            };
            return customLabels[val] || '';
          },
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
          },
          align: 'right' as 'right',
          offsetX: 5,
          offsetY: -20,
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
    },
  });

  useEffect(() => {
    const { driverId, timeFrom, timeTo, timeZone, accessToken, refreshToken, companyName } = getQueryParams();

      if (!driverId || !timeFrom || !timeTo || !timeZone || !accessToken) {
        console.error('Missing required query parameters');
        return;
      }
    const loadData = async () => {
      try {
        const data = await fetchDriverLog(
          driverId,
          timeTo,
          timeFrom,
          timeZone,
          accessToken,
          refreshToken,
          companyName,
          );
  
        if (!data) {
          console.error('Failed to fetch data');
          return;
        }
  
        data.sort((a, b) => new Date(a.servertime).getTime() - new Date(b.servertime).getTime());
  
        const seriesData = data.reduce((acc: { x: number; y: number }[], log) => {
          const timeString = log.shiftedTime.split(', ')[1];
          const [time, meridiem] = timeString.split(' ');
          const [hours, minutes, seconds] = time.split(':').map(Number);
  
          const totalMilliseconds =
            ((meridiem === 'PM' && hours !== 12 ? hours + 12 : hours === 12 && meridiem === 'AM' ? 0 : hours) * 3600 +
              minutes * 60 +
              seconds) * 1000;
  
          const statusValue = statusMapping[log.status];
  
          if (statusValue === undefined) return acc;
  
          const lastPoint = acc[acc.length - 1];
  
          // adding itermediate pioints
          if (lastPoint && lastPoint.y !== statusValue) {
            acc.push({
              x: totalMilliseconds,
              y: lastPoint.y,
            });
          }
  
          acc.push({
            x: totalMilliseconds,
            y: statusValue,
          });
  
          return acc;
        }, []);
  
        // adding last point in the day
        const now = new Date();
        const companyTime = new Date(
          new Date(now).toLocaleString("en-US", { timeZone })
        );
        const lastPoint = seriesData[seriesData.length - 1];
        if (lastPoint) {
          const isToday = new Date(timeFrom).toDateString() === companyTime.toDateString();
          const lastX = isToday
            ? (companyTime.getHours() * 3600 + companyTime.getMinutes() * 60 + companyTime.getSeconds()) * 1000
            : 86400000 - 1; 
  
          seriesData.push({
            x: lastX,
            y: lastPoint.y, 
          });
        }
  
        setChartData((prev) => ({
          ...prev,
          series: [
            {
              name: 'Driver Status',
              data: seriesData,
            },
          ],
        }));
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };
  
    loadData();
  }, []);
  
  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={250}
        width={'100%'}
      />
    </div>
  );
};

export default MobileChart;
