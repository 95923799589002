import { useState, useEffect } from 'react';
import { MantineReactTable, useMantineReactTable, } from 'mantine-react-table';
import { Button, Flex, LoadingOverlay } from '@mantine/core';
import { useDriverLog } from '../../context/ELDLogContext';
import ELDLogsTableTopBtns from './ELDLogsTableTopBtns';
import { getBackgroundGradient } from '../../utils/ELDTableUtils';
import { useTableColumns } from './ELDLogTableColumns';
import { parseELDDataToTableFormat } from '../../../../../utils/DriverlogTable';
import { handleCancelButton, openCancelModal } from '../../services/eldLogCancelEdit';
import { handleSaveButton } from '../../services/eldLogSaveData';
import { useUnsavedChangesWarning } from '../../../../Shared/hooks/useUnsavedChangesWarning ';
import { handleCellEdit } from '../../services/handleCellEdit';
import { ELDLogFixed, UserLog } from '../../types/ELDLog.types';
import { handleInsertEvent } from '../../services/eldLogInsertEvent';
import { handleRemoveEvent } from '../../services/handleRemoveEvent';
import { handleShiftEvent } from '../../services/handleShiftEvent';


const ELDLogChartTable = () => {
  const { driverLog, refetchDriverLog, currentDriver, loading } = useDriverLog();
  const [data, setData] = useState(driverLog || []);
  const [changedCells, setChangedCells] = useState<string[]>([]);
  const [originalData, setOriginalData] = useState(driverLog || []);
  const [deletedRows, setDeletedRows] = useState<UserLog[]>([]);

  const columns = useTableColumns({});

  useUnsavedChangesWarning(changedCells.length > 0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!driverLog) {
          console.log('driverLog is undefined');
        }

        const formattedData = await parseELDDataToTableFormat(driverLog);

        // @ts-ignore
        setOriginalData(formattedData)
        // @ts-ignore
        setData(formattedData);
      } catch (error) {
        console.error('Error parsing driver log data:', error);
        // setIsLoadingData(false);
      } finally {
        // setIsLoadingData(false);
      }
    };

    fetchData();
  }, [driverLog]);

  const handleRemove = (selectedRows: UserLog[]) => {
    handleRemoveEvent(
      selectedRows,
      data,
      setData,
      setDeletedRows,
      setChangedCells,
      table
    );
  };

  const handleShift = (selectedRows: UserLog[]) => {
    handleShiftEvent(
      selectedRows,
      data,
      setData,
      setDeletedRows,
      setChangedCells,
      table
    );
  };



  const table = useMantineReactTable({
    columns,
    data: data,
    enablePagination: false,
    editDisplayMode: "cell",
    createDisplayMode: 'modal', // ('row', 'modal', and 'custom' are also available)
    enableEditing: true,
    enableColumnResizing: true,
    enableRowSelection: true,
    mantineSelectCheckboxProps: { color: 'blue', size: 'xs' },
    initialState: {

      density: 'xs',
      columnVisibility: {
        duration: false,
        distance: false,
        elapsedEngineHours: false,
        elapsedOdometer: false,
      },
      sorting: [
        {
          id: 'date',
          desc: false,
        },
        {
          id: 'startTime',
          desc: false,
        },
      ]
    },
    mantineEditTextInputProps: ({ cell }) => ({
      onBlur: (event) => {
        handleCellEdit({
          cell,
          eventValue: event.target.value,
          originalData,
          setData,
          setChangedCells,
        });
      },
    }),
    renderBottomToolbarCustomActions: () => (
      <Flex align="center" gap="md">
        <Button
          color="blue"
          onClick={() =>
            handleSaveButton(
              changedCells,
              data,
              deletedRows,
              refetchDriverLog,
              setChangedCells,
              setDeletedRows
            )
          }
          disabled={!changedCells.length}
        >
          Save
        </Button>
        <Button
          color="red"
          onClick={() => openCancelModal(() => handleCancelButton(setChangedCells, refetchDriverLog))}
          disabled={!changedCells.length}
        >
          Cancel
        </Button>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Flex align="center" gap="md">
        <ELDLogsTableTopBtns
          table={table}
          onInsertEvent={() => handleInsertEvent({ data, setData, setChangedCells })}
          onRemoveEvent={handleRemove}
          onShiftEvent={handleShift}
        />
      </Flex>
    ),
    mantineTableBodyCellProps: (cell) => ({
      style: {
        background: getBackgroundGradient(cell.row.original.status),
        color: changedCells.includes(cell.cell.id) ? 'red' : 'black',
        fontWeight: changedCells.includes(cell.cell.id) ? 'bold' : 'normal',
      },
    }),

  })

  return (<>
    <div>{changedCells ? `Changes saved: ${changedCells.join(' ,')}` : 'no changes'}</div>
    <MantineReactTable table={table} />

  </>)
};

export default ELDLogChartTable;

function getSelectedRowModel() {
  throw new Error('Function not implemented.');
}
