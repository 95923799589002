import { useMemo } from 'react';
import { MRT_ColumnDef } from 'mantine-react-table';
import { UserLog } from '../../types/ELDLog.types';
import { statusOptions, isActiveOptions, originOptions, noteOptions } from '../../constants/ELDLogConstants';
import Location from './cellComponents/Location';

export const useTableColumns = (validationErrors: Record<string, string | undefined>): MRT_ColumnDef<UserLog>[] => {

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    return `${year}/${day}/${month}`;
  }

  return useMemo(
    () => [
      {
        accessorKey: 'status',
        header: 'Status',
        editVariant: 'select',
        size: 110,
        mantineEditSelectProps: () => ({ data: statusOptions, searchable: false }),
        mantineEditTextInputProps: () => ({
          error: validationErrors['status'],
        }),
      },
      {
        accessorKey: 'sequence_id_number',
        header: 'S/N',
        editVariant: 'text',
        size: 100,
        mantineEditTextInputProps: () => ({
          error: validationErrors['sequence_id_number'],
        }),
        enableEditing: false,
      },
      {
        accessorKey: 'truckid',
        header: 'Truck',
        editVariant: 'text',
        size: 110,
        mantineEditTextInputProps: () => ({
          error: validationErrors['truckid'],
        }),
        enableEditing: false,
      },
      {
        accessorKey: 'date',
        header: 'Date',
        size: 110,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          type: 'date',
          error: validationErrors['date'],
        }),
        Cell: ({ cell }) => {
          const dateValue = cell.getValue() as string;
          return dateValue ? formatDate(dateValue) : 'Invalid date';
        },
      },
      {
        accessorKey: 'startTime',
        header: 'Start Time',
        size: 100,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          type: 'time',
          error: validationErrors['startTime'],
        }),
        // Cell: ({ cell }) => {
        //   const timeValue = cell.getValue() as string;
        
        //   return timeValue;
        // },
      },
      {
        accessorKey: 'odometer',
        header: 'Odometer',
        size: 100,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          error: validationErrors['odometer'],
        }),
      },
      {
        accessorKey: 'engineHours',
        header: 'Eng. hours',
        size: 100,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          error: validationErrors['engineHours'],
        }),
      },
      {
        accessorKey: 'duration',
        header: 'Duration',
        size: 100,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          error: validationErrors['duration'],
        }),
      },
      {
        accessorKey: 'distance',
        header: 'Distance',
        size: 100,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          error: validationErrors['distance'],
        }),
      },
      {
        accessorKey: 'location',
        header: 'Location',
        size: 140,
        Cell({ cell, table, row }) {
          const latitude = row.original.latitude;
          const longitude = row.original.longitude;
          
          return <Location cell={cell} table={table} row={row} latitude={latitude} longitude={longitude} />;
        },
        mantineEditTextInputProps: () => ({
          error: validationErrors['location'],
        }),
        enableEditing: false,
      },
      {
        accessorKey: 'latitude',
        header: 'Lat',
        size: 100,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          error: validationErrors['latitude'],
        }),
      },
      {
        accessorKey: 'longitude',
        header: 'Lon',
        size: 100,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          error: validationErrors['longitude'],
        }),
      },
      {
        accessorKey: 'elapsedEngineHours',
        header: 'El.Eng.Hours',
        size: 100,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          error: validationErrors['elapsedEngineHours'],
        }),
      },
      {
        accessorKey: 'elapsedOdometer',
        header: 'El.Odometer',
        size: 100,
        editVariant: 'text',
        mantineEditTextInputProps: () => ({
          error: validationErrors['elapsedOdometer'],
        }),
      },
      {
        accessorKey: 'record_status',
        header: 'Is Active',
        size: 80,
        editVariant: 'select',
        mantineEditSelectProps: () => ({ data: isActiveOptions, searchable: false }),
        mantineEditTextInputProps: () => ({
          error: validationErrors['record_status'],
        }),
      },
      {
        accessorKey: 'record_origin',
        header: 'Origin',
        searchable: false,
        size: 80,
        editVariant: 'select',
        mantineEditSelectProps: () => ({ data: originOptions, searchable: false }),
        mantineEditTextInputProps: () => ({
          error: validationErrors['record_origin'],
        }),
      },
      {
        accessorKey: 'note',
        header: 'Note',
        editVariant: 'select',
        mantineEditSelectProps: () => ({ data: noteOptions, searchable: false }),
        mantineEditTextInputProps: () => ({
          error: validationErrors['note'],
        }),
      },
    ],
    [validationErrors],
  );
};
