export enum Environment {
    Development = 'development',
    Production = 'production',
}

const config = {
    [Environment.Development]: {
        BASE_URL: 'http://localhost:3000',
    },
    [Environment.Production]: {
        BASE_URL: 'http://logifyeld.com:3000',
    },
};

const currentEnvironment = process.env.NODE_ENV as Environment || Environment.Development;

const currentConfig = config[currentEnvironment];

export const BASE_URL = currentConfig.BASE_URL;
