export const getBackgroundGradient = (status: string): string => {
  switch (status) {
    case 'OFF':
      return 'rgba(235,235,235,0.36)';
    case 'ON':
      return 'rgba(121,202,255,100)';
    case 'DRV':
      return 'rgba(132,255,188,100)';
    case 'SB':
      return 'rgba(255,183,0,0.36)';
    case 'I':
      return 'rgba(255,95,95,1)';
    case 'LI':
      return 'rgba(95,255,138,1)';
    case 'LO':
      return 'rgba(255,159,95,1)';
    case 'PU':
      return 'rgba(235,255,243,100)';
    case 'PD':
      return 'rgba(255,230,235,100)';
    case 'C':
      return 'rgba(95,222,255,1)';
    default:
      return 'rgba(255,0,0,0.35)';
  }
};