import { Button, Group, Box, Paper } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import api from '../components/Login/Authentication';
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function AdminPage() {
    const [driverTokens, setDriverTokens] = useState({
        accessToken: '',
        refreshToken: ''
    });

    const form = useForm({
        initialValues: {
            eventTime: new Date(),
            activeButton: '',
        },
    });

    const loginKuba = async () => {
        try {
            const response = await axios.post('/api/v1/driver/login', {
                "username": "test-driver@gmail.com",
                "password": "1q2w3e",
                "longitude": 123,
                "latitude": 456,
                "note": "test",
                "location": "test"
            });
            console.log('Driver login response:', response.data);

            setDriverTokens({
                accessToken: response.data.tokens.accessToken,
                refreshToken: response.data.tokens.refreshToken
            });

            localStorage.setItem('driverAccessToken', response.data.tokens.accessToken);
            localStorage.setItem('driverRefreshToken', response.data.tokens.refreshToken);

        } catch (error) {
            console.error('Login error:', error);
        }
    };

    const refreshDriverToken = async () => {
        try {
            const response = await api.post('/api/v1/driver/token/refresh', {
                refreshToken: driverTokens.refreshToken
            });

            console.log('Token refreshed:', response.data);

            setDriverTokens({
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken
            });

            localStorage.setItem('driverAccessToken', response.data.accessToken);
            localStorage.setItem('driverRefreshToken', response.data.refreshToken);

            return response.data.accessToken;
        } catch (error) {
            console.error('Token refresh error:', error);
            throw error;
        }
    };

    const handleTokenExpiration = async () => {
        const token = localStorage.getItem('driverAccessToken');
        const tokenExpiry = localStorage.getItem('driverTokenExpiry');

        // if (!token || !tokenExpiry || new Date() > new Date(tokenExpiry)) {
        //     try {
        //         const newAccessToken = await refreshDriverToken();
        //         return newAccessToken;
        //     } catch (error) {
        //         console.error('Failed to refresh token:', error);
        //         return null;
        //     }
        // }

        return token;
    };

    const handleSubmit = async (values: any) => {
        console.log('Form values:', values);

        try {
            const token = await handleTokenExpiration();

            if (!token) {
                console.error('No access token available');
                return;
            }

            const response = await axios.post('/api/v1/report/event', {
                servertime: form.values.eventTime,
                event: form.values.activeButton,
                latitude: 42.1489067,
                longitude: -88.34798,
                location: "SB Test event location",
                note: "SB test event note7777",
                driverid: "aded6721-19b1-40fd-861c-159f655a6aba",
                deviceId: 100
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            console.log('Event submitted:', response.data);
        } catch (error) {
            console.error('Error submitting event:', error);
        }
    };

    useEffect(() => {
        const storedAccessToken = localStorage.getItem('driverAccessToken');
        const storedRefreshToken = localStorage.getItem('driverRefreshToken');

        if (storedAccessToken && storedRefreshToken) {
            setDriverTokens({
                accessToken: storedAccessToken,
                refreshToken: storedRefreshToken
            });
        }
    }, []);

    function handleButtonClick(buttonType: string) {
        form.setFieldValue('activeButton', buttonType);
    }

    return (
        <Box maw={300} mx="auto">
            <Button onClick={loginKuba}>Login Kuba</Button>
            <Paper shadow="sm" p={20} mb={50}>
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <DateTimePicker
                        label="Pick date and time"
                        placeholder="Pick date and time"
                        maw={400}
                        mx="auto"
                        {...form.getInputProps('eventTime')}
                    />

                    <Group position="center" mt="md">
                        <Button
                            type="submit"
                            onClick={() => handleButtonClick('ON')}
                            color={form.values.activeButton === 'ON' ? 'blue' : 'default'}
                        >
                            ON
                        </Button>
                        <Button
                            type="submit"
                            onClick={() => handleButtonClick('OFF')}
                            color={form.values.activeButton === 'OFF' ? 'gray' : 'default'}
                        >
                            OFF
                        </Button>
                        <Button
                            type="submit"
                            onClick={() => handleButtonClick('SB')}
                            color={form.values.activeButton === 'SB' ? 'yellow' : 'default'}
                        >
                            SB
                        </Button>
                        <Button
                            type="submit"
                            onClick={() => handleButtonClick('DRV')}
                            color={form.values.activeButton === 'DRV' ? 'green' : 'default'}
                        >
                            DRV
                        </Button>
                    </Group>
                </form>
            </Paper>
        </Box>
    );
}
