import { UserLog } from '../types/ELDLog.types';

interface HandleInsertEventProps {
    data: UserLog[];
    setData: React.Dispatch<React.SetStateAction<UserLog[]>>;
    setChangedCells: React.Dispatch<React.SetStateAction<string[]>>;
}

export const handleInsertEvent = ({
    data,
    setData,
    setChangedCells,
}: HandleInsertEventProps) => {
    const newEvent: UserLog = {
        angle: data[0].angle,
        assignedcompanyid: data[0].assignedcompanyid,
        data: data[0].data,
        date: data[0].date,
        deviceid: data[0].deviceid,
        distance: data[0].distance,
        driverid: data[0].driverid,
        duration: '',
        elapsedEngineHours: 0,
        elapsedOdometer: 0,
        engineHours: data[0].engineHours,
        event_code: data[0].event_code,
        event_status: 'New',
        fuel_level: data[0].fuel_level,
        id: 0,
        latitude: data[0].latitude,
        location: 'no data',
        longitude: data[0].longitude,
        note: data[0].note,
        odometer:  data[0].odometer,
        origin: 0,
        record_origin: data[0].record_origin,
        record_status: data[0].record_status,
        satellites: data[0].satellites,
        seat_belt_status: data[0].seat_belt_status,
        seqNumber: '',
        sequence_id_number: data[0].sequence_id_number,
        servertime: data[0].servertime,
        shiftedTime: data[0].shiftedTime,
        sourceTable: data[0].sourceTable,
        speed: data[0].speed,
        startTime: data[0].startTime,
        status: 'OFF',
        timezone_truck: data[0].timezone_truck,
        totalDistance: data[0].totalDistance,
        totalEngineHours: 0,
        truckid: data[0].truckid,
        vehicle: ''
    };
console.log('data', data);
console.log('newEvent', newEvent);

    setData((prevData) => {
        const updatedData = [...prevData, newEvent];
        return updatedData;
    });

    setChangedCells((prev) => [...prev,
    `${data.length}_status`,
    `${data.length}_sequence_id_number`,
    `${data.length}_vehicle`,
    `${data.length}_date`,
    `${data.length}_startTime`,
    `${data.length}_odometer`,
    `${data.length}_engineHours`,
    `${data.length}_duration`,
    `${data.length}_distance`,
    `${data.length}_location`,
    `${data.length}_latitude`,
    `${data.length}_longitude`,
    `${data.length}_elapsedEngineHours`,
    `${data.length}_elapsedOdometer`,
    `${data.length}_record_status`,
    `${data.length}_record_origin`,
    `${data.length}_note`
    ]
    );
};
