import { notifications } from "@mantine/notifications";
import { ELDLogFixed, UserLog } from "../types/ELDLog.types";
import api from "../../../Login/Authentication";
import { mapUIStatusToDB } from "../../../Shared/constants/eventStatusMapper";
import { UIStatus } from "../../../Shared/types/UIStatusType";
import { DBStatus } from "../../../Shared/types/DBStatusType";
import React from "react";

export const handleSaveButton = async (
    changedCells: string[],
    data: UserLog[],
    deletedRows: UserLog[],
    refetchDriverLog: () => void,
    setChangedCells: (value: string[]) => void,
    setDeletedRows: React.Dispatch<React.SetStateAction<UserLog[]>>
) => {
    const uniqueIndexes = Array.from(
        new Set(changedCells.map(cellId => parseInt(cellId.split('_')[0])))
    );

    const dataToSave = data.filter((_, index) => uniqueIndexes.includes(index));

    try {
        await saveData(dataToSave, deletedRows);

        notifications.show({
            title: 'Saved',
            message: 'Your changes have been saved successfully.',
            color: 'green',
        });
        setChangedCells([]);
        setDeletedRows([])
        refetchDriverLog();
    } catch (error) {
        console.log('error', error);
        console.error('Error saving driver log:', error);
        notifications.show({
            title: 'Error',
            message: 'There was an error saving the changes.',
            color: 'red',
        });
    }
};

const saveData = async (data: UserLog[], deletedRows: UserLog[]): Promise<void> => {
    console.log('data all', data);
    try {
        const dataToSave = data.map(item => {
            const location =
                React.isValidElement(item.location) && 'props' in item.location
                    ? (item.location.props as { children?: React.ReactNode }).children
                    : 'no data';


            const data = item.data.log_uuid;
            const event: DBStatus = mapUIStatusToDB(item.status as UIStatus);

            function convertToISO(date: string) {
                const [month, day, year] = date.split('/');
                const isoDateTimeString = `${year}-${month}-${day}`;
                return isoDateTimeString;
            }

            function getTimeZoneOffset(date: Date, timeZone: string): string {
                const options = { timeZone, timeZoneName: 'short' } as const;
                const formatter = new Intl.DateTimeFormat([], options);
                const parts = formatter.formatToParts(date);
                const timeZonePart = parts.find(part => part.type === 'timeZoneName') as Intl.DateTimeFormatPart;
                return timeZonePart.value;
            }

            const timeShiftNow = getTimeZoneOffset(new Date(`${convertToISO(item.date)}T${item.startTime}`), item.timezone_truck)
            const dateString = `${item.date.split('/')[2]} ${item.date.split('/')[0]} ${item.date.split('/')[1]} ${item.startTime}` + ' ' + timeShiftNow
            const dirtyUTCDate = new Date(dateString).toUTCString()
            const date = new Date(dirtyUTCDate).toISOString().slice(0, 19)

            return {
                angle: item.angle,
                assignedcompanyid: item.assignedcompanyid,
                data: data,
                deviceid: item.deviceid,
                distance: item.distance,
                driverid: item.driverid,
                engine_hours: item.engineHours,
                event_status: item.event_status || null,
                event_code: item.event_code,
                event: event,
                fuel_level: item.fuel_level,
                id: item.id,
                latitude: item.latitude,
                location: location,
                longitude: item.longitude,
                note: item.note,
                record_origin: item.record_origin,
                record_status: item.record_status,
                satellites: item.satellites,
                seat_belt_status: item.seat_belt_status,
                sequence_id_number: item.sequence_id_number,
                servertime: item.servertime ? date + 'Z' : null,
                sourceTable: item.sourceTable,
                speed: item.speed,
                timezone_truck: item.timezone_truck,
                total_distance: item.odometer,
                truckid: item.truckid,
            };
        });

        const updatedData = dataToSave.filter(item => item.event_status === null);
        const newData = dataToSave.filter(item => item.event_status === 'New');
        const deletedIds = deletedRows.map(item => (item.id));
        if (updatedData.length > 0) {
            console.log('updatedData', updatedData);
            
            // await api.patch("/api/v1/report/update", updatedData);
        }
        if (newData.length > 0) {
            console.log('newData', newData);
            // await api.post("/api/v1/report/new", newData);
        }

        if (deletedIds.length > 0) {
            console.log('deletedIds', deletedIds);
            // await api.delete("/api/v1/report/delete", { data: { ids: deletedIds } });
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            throw new Error(`Failed to save data: ${error.message}`);
        } else {
            throw new Error("An unexpected error occurred while saving data.");
        }
    }
};
