import api from '../../../Login/Authentication';
import type { UserLog } from '../types/ELDLog.types';

const activeCompany = localStorage.getItem('activeCompany');
const companyTimezone = activeCompany ? JSON.parse(activeCompany).timeZone : '';

export const fetchDriverLog = async (id: string, dateTo: string, dateFrom: string): Promise<UserLog[] | null> => {
  try {
    const response = await api.get(`/api/v1/report/driver?driverid=${id}&to=${dateTo}&from=${dateFrom}&timeZone=${companyTimezone}`);

    const data = response.data;
    console.log('response.data', data);

    data.forEach((item: UserLog) => {
      const servertime = new Date(item.servertime);
      item.timezone_truck = item.timezone_truck || companyTimezone
      item.shiftedTime = new Date(servertime).toLocaleString("en-US", { timeZone: companyTimezone });
    });
    console.log('data', data, companyTimezone);

    return data;
  } catch (error) {
    console.error('Error fetching driver log:', error);
    return null;
  }
};
