import { Button, Flex, Group, Select, Text, Tooltip } from "@mantine/core";
import { IconCircleCheckFilled, IconLogout, IconUserCircle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, Location, useNavigate } from "react-router-dom";
import companyStore from "../Store/companyStore";
import { CompanySettings } from "./Administration/Companies/Companies.types";
import api from "./Login/Authentication";
import { observer } from 'mobx-react-lite';
import { pageNames } from "./Shared/constants/pageNames";
import { useMediaQuery } from "@mantine/hooks";
import userStore from "../Store/userStore";

const Header = observer(() => {
  const [title, setTitle] = useState<string>('');
  const [activeCompanyId, setActiveCompanyId] = useState<number | null>(companyStore.getActiveCompany()?.id || null);
  
  const location: Location = useLocation();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  const handleLogout = async () => {
    try {
      await userStore.logout(); 
      navigate('/login', { replace: true }); 
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  useEffect(() => {
    const currentTitle = pageNames[location.pathname];
    setTitle(currentTitle || "Page");
  }, [location]);

  const handleCompanySwitch = async (companyId: number) => {
    const selectedCompany = userData.companies.find((company: CompanySettings) => company.id === companyId);
    if (!selectedCompany) return;

    companyStore.setActiveCompany(selectedCompany);
    await api.post(`/api/v1/company/${selectedCompany.id}`); // select company endpoint
    setActiveCompanyId(selectedCompany.id);
    window.location.reload();
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  
  return (
    <Group
      mih={60}
      bg="black"
      align="center"
      position="apart"
      pr={isSmallScreen ? 10 : 50}
      pl={isSmallScreen ? 70 : 100}
    >
      {!isSmallScreen && <Text color="white">{title}</Text>}

      <Select
        data={userData.companies.map((company: CompanySettings) => ({
          value: company.id.toString(),
          label: company.carrierName,
        }))} 
        placeholder={isSmallScreen ? "" : "Select a company"}
        radius="xl"
        size="xs"
        value={activeCompanyId ? activeCompanyId.toString() : null}
        onChange={(value) => handleCompanySwitch(Number(value))}
        searchable={false}
        icon={<IconCircleCheckFilled size={16} color="green" />}
        variant="filled"
      />

      <Flex gap={10}>
        <Tooltip label={isSmallScreen ? "" : ` Enter ${userData.user.firstName}'s profile`}>
          <Button
            leftIcon={<IconUserCircle size="1rem" />}
            radius="xl"
            size="xs"
            maw={150}
          >
            {!isSmallScreen && (
              <Text truncate>{userData.user.firstName}!</Text>
            )}
          </Button>
        </Tooltip>
        <Tooltip label={isSmallScreen ? "" : "Exit app?"}>
          <Button
            leftIcon={<IconLogout size="1rem" />}
            radius="xl"
            color="red"
            size="xs"
            onClick={handleLogout}
          >
            {!isSmallScreen && "Log out"}
          </Button>
        </Tooltip>
      </Flex>
    </Group>
  );
});

export default Header;
