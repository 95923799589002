import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { UserLog } from '../types/ELDLog.types';
import { getDrivers } from '../../../Administration/Drivers/Drivers.utils';
import { Driver } from '../../../Administration/Drivers/Drivers.types';
import { fetchDriverLog } from '../services/ELDLogService';

export const ELDLogContext = createContext<{
  driverLog: UserLog[] | null;
  driversList: Driver[] | null;
  currentDriver: Driver | null;
  refetchDriverLog: () => Promise<void>;
  loading: boolean,
  resetRowSelection: () => void,
  setTableRef: (ref: any) => void
}>(null!);

interface ProviderProps {
  children: ReactNode;
}

export const DriverLogProvider = ({ children }: ProviderProps) => {
  const location = useLocation();
  const [driverLog, setDriverLog] = useState<UserLog[] | null>(null);
  const [currentDriver, setCurrentDriver] = useState<Driver | null>(null);
  const [driversList, setDriversList] = useState<Driver[] | null>(null);
  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const driverId = queryParams.get('id');
  const dateTo = queryParams.get('to');
  const dateFrom = queryParams.get('from');

  const tableRef = useRef<any>(null)

  const resetRowSelection = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.resetRowSelection(); 
    }
  }, []);

  const setTableRef = useCallback((ref: any) => {
    tableRef.current = ref;
  }, []);

  useEffect(() => {
    const fetchDriversList = async () => {
      try {
        const drivers = await getDrivers();
        setDriversList(drivers);
      } catch (error) {
        console.error('Error fetching drivers list:', error);
      }
    };

    fetchDriversList();
  }, []);

  const refetchDriverLog = useCallback(async () => {
    if (driverId && dateTo && dateFrom) {
      setLoading(true);
      try {
        const fetchedLogs = await fetchDriverLog(driverId, dateTo, dateFrom);
        setDriverLog(fetchedLogs);
      } catch (error) {
        console.error('Error refetching driver log:', error);
      } finally {
        setLoading(false); 
      }
    }
  }, [driverId, dateTo, dateFrom]);

  useEffect(() => {
    const findCurrentDriver = (drivers: Driver[], id: string) => 
      drivers.find(driver => driver.id === id) || null;

    if (driverId && dateTo && dateFrom && driversList) {
      const currentDriver = findCurrentDriver(driversList, driverId);
      setCurrentDriver(currentDriver);
      refetchDriverLog();
    }
  }, [driverId, dateTo, dateFrom, driversList, refetchDriverLog]);

  return (
    <ELDLogContext.Provider value={{
      driverLog,
      driversList,
      currentDriver,
      refetchDriverLog,
      loading, 
      resetRowSelection,
      setTableRef
    }}>
      {children}
    </ELDLogContext.Provider>
  );
};

export const useDriverLog = () => useContext(ELDLogContext);
