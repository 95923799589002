import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mantine/core";
import { useDriverLog } from "../context/ELDLogContext";

const DriverLogPrevNextBtns = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { resetRowSelection } = useDriverLog();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const dateTo = queryParams.get('to') ?? '';
  const dateFrom = queryParams.get('from') ?? '';

  const currentDateTo: Date = dateTo ? new Date(dateTo) : new Date();
  const currentDateFrom: Date = dateFrom ? new Date(dateFrom) : new Date();

  const formatDate = (date: Date): string => {
    return date.toISOString().split('T')[0]; 
  };

  const today = new Date();
  today.setUTCHours(23, 59, 59, 999); 

  const handlePrev = (): void => {
    const newDateFrom = new Date(currentDateFrom);
    newDateFrom.setUTCDate(newDateFrom.getUTCDate() - 1);

    const newDateTo = new Date(currentDateTo);
    newDateTo.setUTCDate(newDateTo.getUTCDate() - 1);

    resetRowSelection();
    navigate(
      `/dashboard/support/ELDLog?id=${id}&to=${formatDate(newDateTo)}T23:59:59.999Z&from=${formatDate(newDateFrom)}T00:00:00.000Z`
    );
  };

  const handleNext = (): void => {
    const newDateFrom = new Date(currentDateFrom);
    newDateFrom.setUTCDate(newDateFrom.getUTCDate() + 1);

    const newDateTo = new Date(currentDateTo);
    newDateTo.setUTCDate(newDateTo.getUTCDate() + 1);

    resetRowSelection();
    if (newDateTo <= today) {
      navigate(
        `/dashboard/support/ELDLog?id=${id}&to=${formatDate(newDateTo)}T23:59:59.999Z&from=${formatDate(newDateFrom)}T00:00:00.000Z`
      );
    }
  };

  const isNextDisabled = currentDateTo.getUTCDate() >= today.getUTCDate();

  return (
    <div className="driverLogTimeChartBtnsRight">
      <div className='driverLogTimeChartBtnDivider'></div>
      <Button 
        className='driverLogTimeChartBtn' 
        onClick={handlePrev}
      >
        {`<< Prev`}
      </Button>
      <Button 
        className='driverLogTimeChartBtn' 
        onClick={handleNext} 
        disabled={isNextDisabled} 
      >
        {`Next >>`}
      </Button>
    </div>
  );
};

export default DriverLogPrevNextBtns;
