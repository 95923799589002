import { ActionIcon, Flex, Group, HoverCard, Text, Tooltip } from '@mantine/core';
import { IconMapPin, IconRewindBackward20, IconRewindForward20 } from '@tabler/icons-react';
import { MRT_Cell, MRT_TableInstance, MRT_Row } from 'mantine-react-table';
import { ReactElement } from 'react';
import { UserLog } from '../../../types/ELDLog.types';

interface LocationProps {
    cell: MRT_Cell<UserLog>;
    table: MRT_TableInstance<UserLog>;
    row: MRT_Row<UserLog>;
    latitude: number;
    longitude: number;
}

const Location: React.FC<LocationProps> = ({ cell, table, row, latitude, longitude }) => {
    return (
        <Group position="left">
            <HoverCard shadow="md" radius={'xl'}>
                <HoverCard.Target>
                    <Tooltip
                        label={
                            cell.getValue() && (cell.getValue() as ReactElement).props
                            ? String((cell.getValue() as ReactElement).props.children)
                            : 'no data'
                        }
                        >
                    <Text>
                        {cell.getValue() && (cell.getValue() as ReactElement).props
                            ? String((cell.getValue() as ReactElement).props.children)
                            : 'no data'}
                            {/* latitude + ' ' + longitude // TODO: update location automaticly */}
                    </Text>
                </Tooltip>
            </HoverCard.Target >
            <HoverCard.Dropdown>
                <Flex align="center" gap="md">
                    <Tooltip label="Show 20 next locations">
                        <ActionIcon
                            variant="filled"
                            radius="xl"
                            onClick={() => {

                                console.log('r', row)
                                console.log('c', cell)
                                console.log('t', table.options.data[0])
                            }
                            }
                            color="blue"
                            size="lg"
                        >
                            <IconRewindBackward20 style={{ width: '70%', height: '70%' }} stroke={1.5} />
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Show on map">
                        <ActionIcon
                            variant="filled"
                            radius="xl"
                            onClick={() => window.open((cell.getValue() as ReactElement).props.href, '_blank')}
                            color="blue"
                            size="lg"
                        >
                            <IconMapPin style={{ width: '70%', height: '70%' }} stroke={1.5} />
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Show 20 next locations">
                        <ActionIcon
                            variant="filled"
                            radius="xl"
                            onClick={() => window.open((cell.getValue() as ReactElement).props.href, '_blank')}
                            color="blue"
                            size="lg"
                        >
                            <IconRewindForward20 style={{ width: '70%', height: '70%' }} stroke={1.5} />
                        </ActionIcon>
                    </Tooltip>
                </Flex>
            </HoverCard.Dropdown>
        </HoverCard>
        </Group >
    )
}
export default Location