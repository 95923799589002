import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";
import 'typeface-poppins';
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MantineProvider withGlobalStyles withNormalizeCSS
    theme={{
      fontFamily: 'Poppins',
      fontFamilyMonospace: 'Monaco, Courier, monospace',
      headings: { fontFamily: 'Poppins' },
      loader: 'dots'
    }}>
    <Notifications />
    <ModalsProvider>
      <App />
    </ModalsProvider>
  </MantineProvider>
);

reportWebVitals();
