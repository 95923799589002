import { makeAutoObservable } from "mobx";
import api, { setCookie, getCookie, isTokenValid, refreshAccessToken } from "../components/Login/Authentication";
import { injectStores } from "@mobx-devtools/tools";
import { Company, SignupFormValues, UserResponse } from "../components/Login/Authentication.types";

class UserStore {
    userData: UserResponse | null = null;
    activeCompany: Company | null = null;
    accessToken: string | null = getCookie("accessToken");
    refreshTokenValue: string | undefined = sessionStorage.getItem("refreshToken") || undefined;
    constructor() {
        makeAutoObservable(this);
    }

    setUserData(data: UserResponse) {
        this.userData = data;
        localStorage.setItem('userData', JSON.stringify(data));
    }

    setActiveCompany(company: Company) {
        this.activeCompany = company;
        localStorage.setItem('activeCompany', JSON.stringify(company));
    }

    setTokens(accessToken: string, refreshToken: string) {
        this.accessToken = accessToken;
        this.refreshTokenValue = refreshToken;
        setCookie("accessToken", accessToken, 60);
        sessionStorage.setItem("refreshToken", refreshToken);
    }

    async login(username: string, password: string) {
        console.log("process.env", process.env);
        try {
            const response = await api.post<UserResponse>("/api/v1/user/signin", { username, password });
            const { tokens, companies } = response.data;

            this.setUserData(response.data);
            this.setActiveCompany(companies[0]);
            this.setTokens(tokens.accessToken, tokens.refreshToken);

        } catch (error) {
            throw error;
        }
    }

    async signup(values: SignupFormValues) {
        try {
            const response = await api.post<UserResponse>('/api/v1/user/signup', {
                username: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                password: values.password,
                confirmPassword: values.confirmPassword,
                dotNumber: values.dotNumber,
                carrierName: values.carrierName,
                address: values.address,
                city: values.city,
                state: values.state,
                timeZone: values.timeZone,
                zipCode: values.zipCode,
            });

            const { tokens, companies } = response.data;

            this.setUserData(response.data);
            this.setActiveCompany(companies[0]);
            this.setTokens(tokens.accessToken, tokens.refreshToken);

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async refreshToken() {
        try {
            const newAccessToken = await refreshAccessToken(this.refreshTokenValue);
            if (newAccessToken) {
                this.accessToken = newAccessToken;
                setCookie("accessToken", newAccessToken, 60);
            }
        } catch (error) {
            throw error;
        }
    }

    async logout() {
        try {
            await api.post('/api/v1/user/signout');
    
            const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            if (name === "logifySessionId" || name) {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            }
        }
    
            sessionStorage.removeItem('refreshToken');
            localStorage.removeItem('userData');
            localStorage.removeItem('activeCompany');
    
            this.userData = null;
            this.activeCompany = null;
            this.accessToken = null;
            this.refreshTokenValue = undefined;
        } catch (error) {
            console.error('Error during logout:', error);
            throw error;
        }
    }

}

const userStore = new UserStore();
injectStores({ userStore });
export default userStore;
