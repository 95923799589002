
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

export const handleCancelButton = (setChangedCells: (value: string[]) => void, refetchDriverLog: () => void) => {
  notifications.show({
    title: 'Cancelled',
    message: 'Your changes have been cancelled.',
    color: 'red',
  });
  setChangedCells([]);
  refetchDriverLog();
  modals.closeAll();
};

export const openCancelModal = (handleCancelButton: () => void) => {
  modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
     `
        This action is so important that you are required to confirm it with a modal. Please click
        one of these buttons to proceed.
   `
    ),
    centered: true,
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: handleCancelButton,
  });
};
