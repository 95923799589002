import { selectOptions } from "../types/ELDLog.types";

export const statusOptions: selectOptions = ['ON', 'SB', 'OFF', 'DRV', 'I', 'LI', 'LO', 'PU', 'PD', 'C'];
export const isActiveOptions: selectOptions = ['1', '2'];
export const originOptions: selectOptions = ['1', '2', '3', '4'];
export const noteOptions: selectOptions = [
'Pick Up',
'Delivery',
'Fuel',
'Inspection',
'PTI',
'Break',
'Sleep',
'Personal',
];