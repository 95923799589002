import { notifications } from "@mantine/notifications";
import { AxiosResponse } from "axios";
import { Driver } from "./Drivers.types";
import api from "../../Login/Authentication";
import { modals } from "@mantine/modals";

export async function getDrivers(): Promise<Driver[]> {
  try {
    notifications.show({
      id: 'Updating',
      title: 'Retrieving drivers data',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });

    const driversResponse: AxiosResponse<Driver[]> = await api.get('/api/v1/driver');
    const driversTable = driversResponse.data;

    notifications.hide('Updating');
    notifications.show({
      title: 'Done',
      message: 'Driver list updated successfully.',
      color: 'teal',
    });

    return driversTable;
  } catch (error: unknown) {
    console.error('Error fetching drivers list:', error);
    notifications.hide('Updating');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while updating drivers list.',
      color: 'red',
    });
    throw error;
  }
}

export async function handleDeleteDriverSubmit(selectedDriver: Driver) {
  try {
    notifications.show({
      id: 'DeletingDriver',
      title: 'Removing driver from company',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });
    console.log('selectedDriver', selectedDriver);
    
    await api.delete(`/api/v1/driver/${selectedDriver.id}`);
    console.log('delete driver', selectedDriver);
    notifications.hide('DeletingTruck');

    notifications.show({
      title: 'Done!',
      message: 'Truck deleted successfully. Page will be reloaded.',
      color: 'teal',
    });
    modals.closeAll();

    setTimeout(() => {
      window.location.reload();
    }, 2000);

  } catch (error: unknown) {
    console.error('Error deleting driver:', error);
    notifications.hide('DeletingDriver');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while removing the truck.',
      color: 'red',
    });
    throw error;
  }
}

export const handleCreateDriverSubmit = async (driver: Driver) => {
  try {
    notifications.show({
      id: 'CreatingDriver',
      title: 'Creating driver',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });

    const driverReadyToSubmit = {
      // TODO add all fields
      FirstName: driver.firstName,
      LastName: driver.lastName,
      Email: driver.email,
      PhoneNumber: driver.phone,
      DriverLicenseNumber: driver.DriverLicenseNumber,
      DriverLicenseState: driver.DriverLicenseState,
      Password: driver.password,
      HoursOfServiceRule: driver.HoursOfServiceRule,
      NumberOfLogsShown: driver.NumberOfLogsShown,
    }

    await api.post(`/api/v1/driver/`, driverReadyToSubmit);

    notifications.hide('CreatingDriver');

    notifications.show({
      title: 'Done!',
      message: 'Driver added successfully. Page will be reloaded.',
      color: 'teal',
    });
    modals.closeAll();

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error: unknown) {
    console.error('Error creating driver:', error);
    notifications.hide('CreatingDriver');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while updating the company.',
      color: 'red',
    });
    throw error;
  }
}
export const handleEditDriverSubmit = async (selectedDriver: Driver) => {
  // TODO: if company is different what should be done next - create clone of a driver? Create isActive status?
  try {
    notifications.show({
      id: 'CreatingDriver',
      title: 'Creating driver',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });

    console.log('selectedDriver', selectedDriver);

    const driverReadyToSubmit = {
      // TODO add all fields
      FirstName:selectedDriver.firstName,
      LastName:selectedDriver.lastName,
      // Email:selectedDriver.email,
      // PhoneNumber:selectedDriver.phone,
      DriverLicenseNumber: selectedDriver.DriverLicenseNumber,
      DriverLicenseState: selectedDriver.DriverLicenseState,
      // Password:selectedDriver.password,
      HoursOfServiceRule: selectedDriver.HoursOfServiceRule,
      NumberOfLogsShown: selectedDriver.NumberOfLogsShown,
      // restart:selectedDriver.restart,
    }

    await api.put(`/api/v1/driver/${selectedDriver.id}`, driverReadyToSubmit);

    notifications.hide('CreatingDriver');

    notifications.show({
      title: 'Done!',
      message: 'Driver added successfully. Page will be reloaded.',
      color: 'teal',
    });
    modals.closeAll();

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error: unknown) {
    console.error('Error creating driver:', error);
    notifications.hide('CreatingDriver');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while updating the company.',
      color: 'red',
    });
    throw error;
  }
}
