import { Button, Group, TextInput, Box, Flex, Select, Checkbox, Paper, MultiSelect, PasswordInput, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { DateInput } from '@mantine/dates';
import { CompanySettings } from '../Companies/Companies.types';
import { HOSAlertData, cargoTypeData, restartData, ruleData, vehicleGroupData } from './Driver.constants';
import { CreateDriverModalProps, Driver } from './Drivers.types';
import states from '../../../data/USstates';
import { handleCreateDriverSubmit } from './Drivers.utils';

function DriverCreateForm({initialValues}: CreateDriverModalProps) {
    const [visible, { toggle }] = useDisclosure(false);

    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData') || '{}').companies : []
    const companiesArray = userData.map((company: CompanySettings) => ({ value: company.id, label: company.carrierName }))

    const form = useForm<Driver>({
        initialValues: initialValues,
        validate: {
            firstName: (value) => {
                if (!value) {
                    return "First name is required";
                }
                if (value.length < 2 || value.length > 30) {
                    return "First name length must be between 2 and 30 characters";
                }
                return null;
            },
            lastName: (value) => {
                if (!value) {
                    return "Last name is required";
                }
                if (value.length < 2 || value.length > 30) {
                    return "Last name length must be between 2 and 30 characters";
                }
                return null;
            },
            email: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : "Invalid email",
            username: (value) => {
                if (!value) {
                    return "Last name is required";
                }
                if (value.length < 2 || value.length > 30) {
                    return "User name length must be between 2 and 30 characters";
                }
                return null;
            },
            phone: (value) => /^\+?[0-9\s]+$/i.test(value) ? null : "Invalid phone number",
            DriverLicenseNumber: (value) => (value !== "" ? null : "Invalid license number"),
            DriverLicenseState: (value) => (value !== "" ? null : "Invalid license state"),
            vehicleGroup: (value) => (value !== "" ? null : "Invalid user name"),
            AssignedCompanyID: (value) => (value !== null ? null : "Invalid company name"),
            NumberOfLogsShown: (value) => (Number(value) > 1 || Number(value) < 365 ? null : `Choose between 1 and 365`),
            password: (value) =>
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/.test(value)
                  ? null
                  : "Invalid password",
            confirmPassword: (value, values) =>
                value !== values.password ? 'Passwords did not match' : null,
            HoursOfServiceRule:(value) => (value !== null ? null : "HOS rule is reqired"),
        },
    });

    const handleSelectChange = (fieldName: string, value: boolean) => {
        form.setFieldValue(fieldName, value);
    };

    return (
        <div className="tableContainer">
            <Box
                className="drivers-table"
                component="form"
                mt={30}
                maw={1000}
                mx="auto"
                onSubmit={form.onSubmit((values: Driver) => {handleCreateDriverSubmit(values)})}
                onReset={form.onReset}
            >
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    gap={{ base: 'sm', sm: 'lg' }}
                    justify={{ sm: 'center' }}
                >
                    <Paper shadow="xs" p="xl" withBorder >
                        <h3>Drivers info</h3>
                        <Flex
                            direction={{ base: 'column', xs: 'row' }}
                            gap={{ base: 'sm', sm: 'lg' }}
                            justify={{ sm: 'center' }}
                            align={{ base: 'start' }}
                            maw={450}
                        >
                            <Group className="drivers-table-column" w={250}>
                                <TextInput label="First Name:" withAsterisk placeholder='Driver`s first name'  {...form.getInputProps('firstName')} />
                                <TextInput label="Last Name:" withAsterisk placeholder='Driver`s last name'  {...form.getInputProps('lastName')} />
                                <TextInput label="Email:" withAsterisk placeholder='Drivers email'  {...form.getInputProps('email')} />
                                <TextInput label="Username:" withAsterisk placeholder='Drivers user name'  {...form.getInputProps('username')} />
                                <TextInput label="Phone:" withAsterisk placeholder='Drivers phone' type="number" {...form.getInputProps('phone')} />
                                <TextInput label="Driver License Number:" withAsterisk placeholder='Drivers license number' {...form.getInputProps('DriverLicenseNumber')} />
                                <Select
                                    label="Driver License State:"
                                    withAsterisk placeholder="Pick one"
                                    data={states}
                                    clearable={true}
                                    {...form.getInputProps('DriverLicenseState')}
                                    onChange={(value) => form.setFieldValue('DriverLicenseState', value)}
                                />

                            </Group>

                            <Group className="drivers-table-column" w={250}>
                                <Select
                                    label="Vehicle Group:"
                                    withAsterisk placeholder="Pick one"
                                    data={vehicleGroupData}
                                    clearable={true}
                                    onChange={(value) => form.setFieldValue('vehicleGroup', value)}
                                />
                                <DateInput
                                    label="Hiring date:"
                                    withAsterisk
                                    clearable={true}
                                    onChange={(value) => form.setFieldValue('hiringDate', value)}
                                />
                                <Select
                                    miw={200}
                                    label="Select company"
                                    data={companiesArray}
                                    withAsterisk
                                    clearable={true}
                                    {...form.getInputProps('AssignedCompanyID')}
                                />
                                <MultiSelect
                                    miw={200}
                                    label="Select supervisor"
                                    data={[]} // TODO: fetch from API when will be implemented
                                    {...form.getInputProps('supervisors')}
                                />
                                <MultiSelect
                                    miw={200}
                                    label="Select dispatcher"
                                    styles={{ dropdown: { maxHeight: 200, overflowY: 'auto', color: 'pink' } }}
                                    data={[]} // TODO: fetch from API when will be implemented
                                    {...form.getInputProps('dispatchers')}
                                />
                                <Stack>
                                    <PasswordInput
                                        miw={200}
                                        label="Password"
                                        visible={visible}
                                        onVisibilityChange={toggle}
                                        {...form.getInputProps('password')}
                                    />
                                    <PasswordInput
                                        miw={200}
                                        label="Confirm password"
                                        visible={visible}
                                        onVisibilityChange={toggle}
                                        {...form.getInputProps('confirmPassword')}
                                    />
                                </Stack>
                            </Group>
                        </Flex>
                    </Paper>

                    <Paper shadow="xs" p="xl" withBorder >
                        <h3>Log settings</h3>
                        <Flex
                            direction={{ base: 'column', xs: 'row' }}
                            gap={{ base: 'sm', sm: 'lg' }}
                            justify={{ sm: 'center' }}
                            align={{ base: 'start' }}
                            maw={450}
                        >
                            <Group className="drivers-table-column" w={250}>
                                <Select
                                    label="Cargo type:"
                                    placeholder="Pick one"
                                    data={cargoTypeData}
                                    onChange={(value) => form.setFieldValue('vehicleGroup', value)}
                                />
                                <Select
                                    label="Rule:"
                                    placeholder="Pick one"
                                    data={ruleData}
                                    withAsterisk
                                    onChange={(value) => form.setFieldValue('HoursOfServiceRule', value)}
                                />
                                {/* <Select
                                    label="Restart:"
                                    placeholder="Pick one"
                                    data={restartData}
                                    onChange={(value) => form.setFieldValue('restart', value)}
                                /> */}
                                <Select
                                    label="Frequency of HOS alert:"
                                    placeholder="Pick one"
                                    data={HOSAlertData}
                                    onChange={(value) => form.setFieldValue('frequencyOfHOSAlert', value)}
                                />
                                <TextInput
                                    label="Number of Logs Shown:"
                                    type="number"
                                    {...form.getInputProps('NumberOfLogsShown')}
                                />
                            </Group>

                            <Group className="drivers-table-column" w={250}>
                                {/* <Checkbox
                                    defaultChecked={form.values.isAllowedChangeSettings}
                                    onChange={() => handleSelectChange('isAllowedChangeSettings', !form.values.isAllowedChangeSettings)}
                                    label="Allow driver to change settings"
                                /> */}
                                <Checkbox
                                    defaultChecked={form.values.enableSendingLogs}
                                    onChange={() => handleSelectChange('enableSendingLogs', !form.values.enableSendingLogs)}
                                    label="Enable sending logs"
                                />
                                <Checkbox
                                    defaultChecked={form.values.is30MinBreak}
                                    onChange={() => handleSelectChange('is30MinBreak', !form.values.is30MinBreak)}
                                    label="30 minutes rest brake"
                                />
                                <Checkbox
                                    defaultChecked={form.values.isAllowYardMove}
                                    onChange={() => handleSelectChange('isAllowYardMove', !form.values.isAllowYardMove)}
                                    label="Allow Yard Move"
                                />
                                <Checkbox
                                    defaultChecked={form.values.isAllowPersonalConv}
                                    onChange={() => handleSelectChange('isAllowPersonalConv', !form.values.isAllowPersonalConv)}
                                    label="Allow personal conveyance"
                                />
                                <Checkbox
                                    defaultChecked={form.values.isAllowDrivingException}
                                    onChange={() => handleSelectChange('isAllowDrivingException', !form.values.isAllowDrivingException)}
                                    label="Allow Adverse Driving Exception"
                                />
                                <Checkbox
                                    defaultChecked={form.values.isExemptDriver}
                                    onChange={() => handleSelectChange('isExemptDriver', !form.values.isExemptDriver)}
                                    label="Exempt Driver"
                                />

                            </Group>
                        </Flex>
                    </Paper>
                </Flex>
                <Group style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop: '20px' }}>
                    <Button size="md" color='red' onClick={() => modals.closeAll()}                    >
                        Cancel
                    </Button>
                    <Button size="md" color='red' onClick={() => form.reset()} variant="outline">
                        Reset
                    </Button>
                    <Button size="md" color='green' type="submit" onClick={() => console.log(form.values)}>
                        Save and submit
                    </Button>
                </Group>
            </Box>
        </div>
    );
}

export default DriverCreateForm;
